import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect: '/recharge'
    component: () => import('@/views/home/index')
  },
  {
    path: '/face-bind',
    name: 'FaceBind',
    component: () => import('@/views/face-bind/face-bind')
  },
  {
    path: '/order-login',
    name: 'Login',
    component: () => import('@/views/login/login')
  },
  {
    path: '/login-fail',
    name: 'LoginFail',
    component: () => import('@/views/loginFail/loginFail')
  },
  // {
  //     path: '/account',
  //     name: 'Account',
  //     component: () => import('@/views/account')
  // },
  // {
  //     path: '/buyProduct',
  //     name: 'BuyProduct',
  //     component: () => import('@/views/buy-product')
  // },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('@/views/recharge/index')
  },
  {
    path: '/recharge-history',
    name: 'RechargeHistory',
    component: () => import('@/views/recharge-history/index')
  },
  {
    path: '/recharge-center',
    name: 'RechargeCenter',
    component: () => import('@/views/recharge/index')
  },
  {
    path: '/consumption',
    name: 'Consumption',
    component: () => import('@/views/consumption/consumption')
  },
  {
    path: '/consumption-detail',
    name: 'ConsumptionDetail',
    component: () => import('@/views/consumption/detail')
  },
  {
    path: '/paycode',
    name: 'Paycode',
    component: () => import('@/views/paycode/paycode')
  },
  {
    path: '/notify',
    name: 'Notify',
    component: () => import('@/views/notify/notify')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('@/views/order/order')
  },
  {
    path: '/order-detail',
    name: 'OrderDetail',
    component: () => import('@/views/order/detail')
  },
  {
    path: '/personal',
    name: 'Personal',
    component: () => import('@/views/personal')
  },
  {
    path: '/verification',
    name: 'Verification',
    component: () => import('@/views/verification/verification')
  },
  {
    path: '/allowance',
    name: 'Allowance',
    component: () => import('@/views/allowance/index')
  },
  {
    path: '/meal-code',
    name: 'MealCode',
    component: () => import('@/views/order/meal-code')
  },
  {
    path: '/debug',
    name: 'Debug',
    component: () => import('@/views/debug/debug')
  },
  {
    path: '/access-record',
    name: 'AccessRecord',
    component: () => import('@/views/access-record/access-record')
  },
  {
    path: '/check-work',
    name: 'CheckWork',
    component: () => import('@/views/check-work/index')
  },
  {
    path: '/leave',
    name: 'Leave',
    component: () => import('@/views/leave/index')
  },
  {
    path: '/to-leave',
    name: 'ToLeave',
    component: () => import('@/views/leave/ask-leave')
  },
  {
    path: '/examine-leave',
    name: 'ExamineLeave',
    component: () => import('@/views/leave/examine-leave')
  },
  {
    path: '/smsAuth',
    name: 'SmsAuth',
    component: () => import('@/views/smsAuth/index')
  },
  {
    path: '/smsAuth/add',
    name: 'SmsAuthAdd',
    component: () => import('@/views/smsAuth/$id')
  },
  {
    path: '/personInfo',
    name: 'PersonInfo',
    component: () => import('@/views/person-info/PersonInfo')
  },
  {
    path: '/accBinding',
    name: 'AccBinding',
    component: () => import('../views/account/AccBinding.vue')
  },
  {
    path: '/accUnbundling',
    name: 'AccUnbundling',
    component: () => import('../views/account/AccUnbundling.vue')
  },
  {
    path: '/pwdSetting',
    name: 'PwdSetting',
    component: () => import('../views/pwdSetting/PwdSetting.vue')
  },
  {
    path: '/faceBinding',
    name: 'FaceBinding',
    component: () => import('@/views/face-bind/FaceBinding.vue')
  },
  {
    path: '/faceCheck',
    name: 'FaceCheck',
    component: () => import('@/views/face-bind/FaceCheck.vue')
  },
  {
    path: '/faceCheckList',
    name: 'FaceCheckList',
    component: () => import('@/views/face-bind/FaceCheckList.vue')
  },
  {
    path: '/center',
    name: 'Center',
    component: () => import('@/views/home/Center.vue')
  },
  {
    path: '/inform-add',
    name: 'InformAdd',
    component: () => import('../views/inform/inform-add.vue')
  },
  {
    path: '/inform-detail',
    name: 'InformDetail',
    component: () => import('../views/inform/inform-detail.vue')
  },
  {
    path: '/inform-list',
    name: 'InformList',
    component: () => import('../views/inform/inform-list.vue')
  },
  {
    path: '/inform-unread',
    name: 'InformUnread',
    component: () => import('../views/inform/inform-unread.vue')
  },
  {
    path: '/inform-userUnread',
    name: 'InformUserUnread',
    component: () => import('../views/inform/inform-userUnread.vue')
  },
  {
    path: '/blocked',
    name: 'Blocked',
    component: () => import('@/views/home/Blocked.vue')
  },
  {
    path: '/userWallet',
    name: 'UserWallet',
    component: () => import('@/views/user/user-wallet.vue')
  },
  {
    path: '/cash-withdrawal',
    name: 'CashWithdrawal',
    component: () => import('@/views/user/cash-withdrawal.vue')
  },
  {
    path: '/refund-detail',
    name: 'RefundDetail',
    component: () => import('@/views/user/refund-detail.vue')
  },
  {
    path: '/appCenter',
    name: 'AppCenter',
    component: () => import('@/views/all/app-center.vue')
  },
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/setting/index.vue')
  },
  {
    path: '/pickInform',
    name: 'PickInform',
    component: () => import('@/views/pick-up/pick-inform.vue')
  },
  {
    path: '/pickUserInfo',
    name: 'PickUserInfo',
    component: () => import('@/views/pick-up/userInfo.vue')
  },
  {
    path: '/pickAddUserInfo',
    name: 'PickAddUserInfo',
    component: () => import('@/views/pick-up/addUserInfo.vue')
  },
  {
    path: '/pick-records',
    name: 'PickRecords',
    component: () => import('@/views/pick-up/pick-records.vue')
  },
  {
    path: '/upload-face',
    name: 'UploadFace',
    component: () => import('@/views/pick-up/upload-face.vue')
  },
  {
    path: '/class-inform',
    name: 'ClassInform',
    component: () => import('@/views/pick-up/class-inform.vue')
  },
  {
    path: '/receipt',
    name: 'Receipt',
    component: () => import('@/views/receipt/receipt.vue')
  },
  {
    path: '/family-list',
    name: 'FamilyList',
    component: () => import('@/views/family/family-list.vue')
  },
  {
    path: '/family-detail',
    name: 'FamilyDetail',
    component: () => import('@/views/family/family-detail.vue')
  },
  {
    path: '/family-apply-detail',
    name: 'FamilyApplyDetail',
    component: () => import('@/views/family/family-apply-detail.vue')
  },
  {
    path: '/my-report-meal',
    name: 'MyReportMeal',
    component: () => import('@/views/my-report-meal/index.vue')
  },
  {
    path: '/report-meal-apply',
    name: 'ReportMealApply',
    component: () => import('@/views/my-report-meal/apply/index.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 解决访问相同路径报错的问题
// 以下两种方法
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// const routerPush = router.push
// router.push = path => {
//   // 判断下当前路由是否就是要跳转的路由
//   if (router.currentRoute.fullPath.includes(path)) return
//   return routerPush.call(router, path)
// }

export default router;
